import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyD533TlnvpKD7FksR-SpSicAwTARGtkVas",
  authDomain: "again-shop-limited.firebaseapp.com",
  databaseURL: "https://again-shop-limited-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "again-shop-limited",
  storageBucket: "again-shop-limited.appspot.com",
  messagingSenderId: "328770436515",
  appId: "1:328770436515:web:232b0f87d2d4caed8aae16",
  measurementId: "G-8NZB70ZSKF"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "AGAIN SHOP LIMITED"; //公司名稱
      },
      get regFormDomain() {
        return "https://againshopltd.xyz/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}